<template>
    <li class="position-relative list-group-item overflow-hidden pl-3 pr-2 py-2"
        v-bind:class="{ 'is-reordering': isReordering }">
        <div class="row no-gutters align-items-center">
            <div class="col-auto mb-2 mb-md-0">
                <b-form-checkbox class="pr-3"
                                 switch
                                 v-model="isActive">
                    {{ isActive ? "🟢" : "🔴" }}
                </b-form-checkbox>
            </div>
            <div class="col-auto flex-grow-1 font-weight-bolder mb-2 mb-md-0">
                {{ name }} ({{ code }})
            </div>
            <div class="col-md-auto text-right">
                <button class="btn btn-sm btn-warning text-nowrap mr-1"
                        v-bind:class="{ 'btn-loading': isLoading }"
                        v-bind:disabled="isReordering"
                        v-on:click="isEditModeActive = true">
                    ✏️
                </button>
                <button class="btn btn-sm btn-danger text-nowrap"
                        v-bind:class="{ 'btn-loading': isLoading }"
                        v-bind:disabled="isReordering"
                        v-on:click="removeRegion">
                    🗑️
                </button>
            </div>
        </div>
        <form class="position-absolute row no-gutters flex-nowrap align-items-center bg-white p-2"
              v-bind:class="{ 'active': isEditModeActive }"
              v-on:submit.prevent.stop="updateRegion">
            <div class="col-6 p-0 pr-1">
                <b-form-input class="bg-light mr-1"
                              size="sm"
                              required
                              placeholder="Name"
                              v-model.trim="name">
                </b-form-input>
            </div>
            <div class="flex-grow-1 p-0 pr-1">
                <b-form-input class=" bg-light"
                              size="sm"
                              required
                              placeholder="ISO 3166 Country Code"
                              v-model.trim="code">
                </b-form-input>
            </div>
            <div class="flex-grow-1 text-right">
                <button class="btn btn-sm btn-primary text-nowrap"
                        type="submit"
                        v-bind:class="{ 'btn-loading': isLoading }">
                    💾 Save
                </button>
            </div>
        </form>
    </li>
</template>

<script>
import { BFormCheckbox, BFormInput, } from "bootstrap-vue";
export default {
    name: "RegionItem",
    components: {
        BFormCheckbox, BFormInput,
    },
    props: {
        region: {
            type: Object,
        },
        isReordering: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            isLoading: false,
            isEditModeActive: false,

            nameLocal: "",
            codeLocal: "",
        };
    },
    computed: {
        isActive: {
            get () {
                return this.region?.isActive ?? false;
            },
            set (val) {
                this.toggleRegion(val);
            }
        },
        name: {
            get () {
                return this.region?.name ?? "";
            },
            set (val) {
                this.nameLocal = val;
            }
        },
        code: {
            get () {
                return this.region?.code ?? "";
            },
            set (val) {
                this.codeLocal = val;
            }
        },
    },
    methods: {
        async toggleRegion (value) {
            try {
                this.isLoading = true;
                await this.$store.dispatch("region/edit", { ...this.region, "isActive": value });
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async updateRegion () {
            const { name, nameLocal, code, codeLocal, } = this;
            if (!nameLocal && !codeLocal) {
                this.isEditModeActive = false;
                return;
            }
            if (name === nameLocal && code === codeLocal) {
                this.isEditModeActive = false;
                return;
            }
            try {
                this.isLoading = true;
                await this.$store.dispatch(
                    "region/edit",
                    { ...this.region, "name": nameLocal || name, "code": codeLocal || code }
                );
                this.isEditModeActive = false;
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async removeRegion () {
            try {
                this.isLoading = true;
                await this.$store.dispatch("region/delete", this.region._id);
                this.isEditModeActive = false;
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.is-reordering {
    cursor: move;
}

form {
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;

    z-index: 1;

    transform: translateY(-100%);
    transition: transform 250ms ease-in-out;

    &.active {
        transform: translateY(0);
    }
}
</style>
