<template>
    <div class="card rounded-xl shadow-sm overflow-hidden">
        <div class="card-header d-flex flex-nowrap justify-content-between px-3 py-2">
            <h5 class="font-weight-bolder mb-0">
                Regions
            </h5>
            <b-form-checkbox switch
                             v-model="isReordering">
                Reorder
            </b-form-checkbox>
        </div>
        <b-overlay v-bind:show="isLoading">
            <draggable tag="ul"
                       class="list-group list-group-flush border-bottom-0"
                       draggable=".item"
                       v-bind:disabled="!isReordering"
                       v-bind:class="{ 'is-shaking': isReordering }"
                       v-model="regions">
                <region-item class="item"
                             v-for="region in regions"
                             v-bind:key="region._id"
                             v-bind:region="region"
                             v-bind:is-reordering="isReordering">
                </region-item>
            </draggable>
        </b-overlay>
        <div class="list-group list-group-flush">
            <form class="d-block list-group-item p-2"
                  v-on:submit.prevent.stop="addRegion">
                <div class="d-flex flex-nowrap">
                    <div class="col-6 p-0 pr-1">
                        <b-form-input class="bg-light mr-1"
                                      size="sm"
                                      required
                                      placeholder="Name"
                                      v-model.trim="name">
                        </b-form-input>
                    </div>
                    <div class="flex-grow-1 p-0 pr-1">
                        <b-form-input class=" bg-light"
                                      size="sm"
                                      required
                                      placeholder="ISO 3166 Country Code"
                                      v-model.trim="code">
                        </b-form-input>
                    </div>
                    <div class="flex-grow-1 p-0">
                        <button class="btn btn-sm btn-success text-nowrap w-100"
                                type="submit">
                            ✨ Create
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { BOverlay, BFormCheckbox, BFormInput, } from "bootstrap-vue";
import RegionItem from "@/components/Settings/RegionItem";
import draggable from "vuedraggable";
export default {
    name: "RegionList",
    components: {
        draggable,
        BOverlay, BFormCheckbox, BFormInput,
        RegionItem,
    },
    data () {
        return {
            isReordering: false,
            isLoading: false,

            name: "",
            code: "",
        };
    },
    computed: {
        regions: {
            get () {
                return this.$store.getters["region/items"];
            },
            set (val) {
                this.reorderRegions(val);
            }
        },
    },
    methods: {
        reset () {
            this.name = "";
            this.code = "";
        },
        async addRegion () {
            try {
                const { name, code } = this;
                this.isLoading = true;
                await this.$store.dispatch("region/add", { name, code });
                this.reset();
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async reorderRegions (regions) {
            try {
                this.isLoading = true;
                await Promise.all(
                    regions.map(
                        ({ _id }, ind) => this.$store.dispatch("region/edit", { _id, "order": ind })
                    )
                );
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.is-shaking {
    animation-name: shake;
    animation-duration: 1200ms;
    animation-iteration-count: infinite;
}

@keyframes shake {
    from,
    to {
        transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translate3d(1px, 0, 0);
    }
}
</style>
